exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/ContactUs.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-ctpat-tsx": () => import("./../../../src/pages/CTPAT.tsx" /* webpackChunkName: "component---src-pages-ctpat-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/Services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-summ-it-guide-summ-it-guide-policy-generator-tsx": () => import("./../../../src/pages/SummITGuide/SummITGuidePolicyGenerator.tsx" /* webpackChunkName: "component---src-pages-summ-it-guide-summ-it-guide-policy-generator-tsx" */),
  "component---src-pages-summ-it-guide-summ-it-guide-third-party-risk-assessment-tsx": () => import("./../../../src/pages/SummITGuide/SummITGuideThirdPartyRiskAssessment.tsx" /* webpackChunkName: "component---src-pages-summ-it-guide-summ-it-guide-third-party-risk-assessment-tsx" */),
  "component---src-pages-summ-it-guide-tsx": () => import("./../../../src/pages/SummITGuide.tsx" /* webpackChunkName: "component---src-pages-summ-it-guide-tsx" */),
  "component---src-pages-summ-it-security-risk-management-tsx": () => import("./../../../src/pages/SummITSecurity/RiskManagement.tsx" /* webpackChunkName: "component---src-pages-summ-it-security-risk-management-tsx" */),
  "component---src-pages-summ-it-security-security-program-automation-tsx": () => import("./../../../src/pages/SummITSecurity/SecurityProgramAutomation.tsx" /* webpackChunkName: "component---src-pages-summ-it-security-security-program-automation-tsx" */),
  "component---src-pages-summ-it-security-third-party-cyber-risk-management-tsx": () => import("./../../../src/pages/SummITSecurity/ThirdPartyCyberRiskManagement.tsx" /* webpackChunkName: "component---src-pages-summ-it-security-third-party-cyber-risk-management-tsx" */),
  "component---src-pages-summ-it-security-tsx": () => import("./../../../src/pages/SummITSecurity.tsx" /* webpackChunkName: "component---src-pages-summ-it-security-tsx" */)
}

